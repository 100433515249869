<template>
  <div style="width: 100%">
    <div class="content-container">
      <a-table
        bordered
        :columns="columnsList"
        :data-source="dealerUsers"
        :customRow="customRow"
        :rowKey="row => row.id"
        class="ordertable"
        @change="onChange"
        :pagination="{ defaultPageSize: 10, hideOnSinglePage: true }"
      >
        <template slot="name" slot-scope="text, record">
          <span v-if="record.client">{{ record.client.code }}</span>
          <span v-if="record.company">{{ record.company.name }}</span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-button
            v-if="record.roles.includes('DEALER_ADMIN')"
            type="primary"
            ghost
            shape="circle"
            icon="edit"
            size="small"
            @click="showDealerPermsModal(record.id)"
            style="margin-right: 2px"
          ></a-button>

          <a-popconfirm
            title="Уверены, что хотите удалить пользователя?"
            ok-text="Удалить"
            cancel-text="Отмена"
            placement="topLeft"
            @confirm="event => onDelete(event, record.id)"
          >
            <a-button
              type="danger"
              ghost
              shape="circle"
              icon="delete"
              size="small"
              @click="onDelete"
            />
          </a-popconfirm>
        </template>
      </a-table>
    </div>
    <a-modal
      :visible="visibleDealerPerms"
      title="Разрешения пользователя дилера"
      @ok="handleDealerPerms"
      @cancel="visibleDealerPerms = false"
      okText="Сохранить"
      cancelText="Закрыть"
      :confirm-loading="dataLoading"
    >
      <a-spin :spinning="dataLoading" tip="Загрузка...">
        <a-row type="flex" justify="center">
          <p>
            <a-checkbox v-model="hasDealerParams">
              Может создавать заказы от имени ДЦ
            </a-checkbox>
          </p>
          <p>
            <a-transfer
              :list-style="{
                width: '200px',
                height: '300px',
              }"
              :target-keys="available_clients"
              :data-source="clients_select"
              :titles="['Доступные', 'Выбранные']"
              :render="item => item.title"
              :showSearch="true"
              @change="handleChange"
              :disabled="!hasDealerParams"
            />
          </p>
        </a-row>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex"
import UserService from "@/services/api/v1/UserService"
function onChange(pagination, filters, sorter) {
  console.log("params", pagination, filters, sorter)
}

export default {
  props: {
    onlyList: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    this.fetchDealerUsers()
  },
  computed: {
    ...mapGetters({
      columns: "dealers/columnsDealerUsers",
      currentDealer: "dealers/currentDealer",
      isOwner: "auth/isOwner",
      isDealer: "auth/isDealer",
      dealerUsers: "dealers/dealerUsers",
      clients: "dealers/manufacturerDealers",
    }),

    columnsList() {
      if (!this.isOwner) {
        return this.columns
      }

      const list = [...this.columns]
      list.pop()
      return list
    },
  },
  data() {
    return {
      selectedRowKeys: [],
      form: this.$form.createForm(this, { name: "advanced_search" }),

      visibleDealerPerms: false,
      hasDealerParams: false,

      available_clients: [],
      dataLoading: false,
      clients_select: [],
      currentUserId: null,
    }
  },
  methods: {
    handleChange(nextTargetKeys) {
      this.available_clients = nextTargetKeys
    },
    async loadData(userId) {
      this.dataLoading = true
      try {
        const response =
          await new UserService().getUserDealerOrdersPermissionsData(userId)
        if (!this.clients.length) {
          await this.fetchDealers()
        }
        this.clients_select = this.clients.map(el => ({
          title: el.code + " - " + el.name,
          key: `${el.id}`,
          description: "",
          disabled: false,
        }))
        const tmp = []
        this.hasDealerParams = parseInt(
          response.data.data.create_orders_under_dc_status
        )
          ? true
          : false

        for (const el of response.data.data.clients) {
          tmp.push(`${el.id}`)
        }
        this.available_clients = tmp
        this.dataLoading = false
      } catch (error) {
        this.dataLoading = false
        this.$message.error(error.message)
      }
    },
    async handleDealerPerms() {
      this.dataLoading = true
      const data = {
        create_orders_under_dc_status: this.hasDealerParams ? 1 : 0,
        clients_id: [...this.available_clients],
      }
      try {
        await new UserService().setUserDealerOrdersPermissionsData(
          this.currentUserId,
          data
        )
        this.dataLoading = false
        this.$message.success("Настройки сохранены")
      } catch (error) {
        this.dataLoading = false
        this.$message.error(error.message)
      }
    },
    showDealerPermsModal(userId) {
      this.currentUserId = userId
      this.visibleDealerPerms = true
      this.loadData(userId)
    },
    onChange,
    ...mapMutations({}),
    ...mapActions({
      deleteDealerUser: "dealers/deleteDealerUser",
      fetchDealerUsers: "dealers/fetchDealerUsers",
      fetchDealers: "dealers/fetchDealers",
    }),
    onPaymentGroupChange(value) {
      this.form.setFieldsValue({
        paymentGroup: value,
      })
    },
    onDelete(event, key) {
      event.stopPropagation()
      if (key || key === 0) {
        this.deleteDealerUser(key)
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleReset() {},
    customRow(record) {
      return {
        on: {
          click: () => {
            //this.$router.push(`/dealerUser/${record.id}`)
            console.info("clicked: ", `/dealerUser/${record.id}`)
          },
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ordertable {
  ::v-deep .ant-table-tbody > tr {
    cursor: pointer;
  }
}
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}
#advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}
.search-result-text {
  margin: 20px 0 10px 0;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
  > button {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}
.top-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 -5px;
  > button {
    &:first-child {
      margin-right: 10px;
    }
  }
}
.filterFields {
  display: flex;
  flex-direction: row;
}
.filterFormItems {
  display: block;
  margin-right: 20px;
}
.selects {
  width: 200px;
}
</style>

<style>
th.column-action,
td.column-action {
  text-align: center !important;
}
</style>
